<script lang="ts" setup></script>

<template>
  <div>
    <h1 class="text-3xl font-bold underline">How to use the website:</h1>
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/PA1zYvXjm1Y?si=Buu94rQX9P9Ih1sG"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>

    <h1 class="text-3xl font-bold underline">Additional notes:</h1>
    <p>
      Hello! This website is still under construction. It will take some time
      until it's ready for the mass public, but for now, feel free to play
      around with it!
    </p>
    &nbsp;
    <p>ETT logs from headset and PC client can BOTH be used.</p>
    <p>
      On PC, the path is:
      .../Oculus/Software/for-fun-labs-eleven-table-tennis-vr/logs
    </p>
    <p>On Quest, the path is: /Android/Data/quest.eleven.forfunlabs/logs</p>
    &nbsp;
    <p>To extract the logs from Quest, there are multiple ways.</p>
    <p>For example, use SideQuest</p>
    <p>
      or <a href="https://adbdownload.com/" _target="_blank">adb</a>: adb pull
      "sdcard/Android/data/quest.eleven.forfunlabs/logs/"
    </p>
  </div>
</template>

<style scoped></style>
